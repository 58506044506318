import { useSelector } from "react-redux";
import { createStore } from "redux"
import { rpc } from "./utils";

import moment from "moment"

type State = {
    user: Person | null
    projects: Project[] | null
    organisations: Organisation[] | null
    persons: Person[] | null
    registrations: Registration[] | null

    curOrganisationId: string | null
    curPersonId: string | null
    curMonth: Month | null
}

function defaultState(): State {
    return {
        user: null,
        projects: null,
        organisations: null,
        persons: null,
        registrations: null,

        curOrganisationId: null,
        curPersonId: null,
        curMonth: null
    }
}

function reducer(state = defaultState(), action: any) {
    switch (action.type) {
        case "SET_USER":
            state = { ...state, user: action.value }
            break;
        case "SET_PROJECTS":
            state = { ...state, projects: action.value }
            break;
        case "SET_ORGANISATIONS":
            state = { ...state, organisations: action.value }
            break;
        case "SET_PERSONS":
            state = { ...state, persons: action.value }
            break;
        case "SET_REGISTRATIONS":
            state = { ...state, registrations: action.value }
            break;

        case "SET_CUR_ORGANISATION_ID":
            state = { ...state, curOrganisationId: action.value }
            break;
        case "SET_CUR_PERSON_ID":
            state = { ...state, curPersonId: action.value }
            break;
        case "SET_CUR_MONTH":
            state = { ...state, curMonth: action.value }
            break;
    }

    return state;
}

export function useUser(): Person | null {
    return useSelector<State, Person | null>(s => s.user)
}
export function useProjects(): Project[] | null {
    return useSelector<State, Project[] | null>(s => s.projects)
}
export function useOrganisations(): Organisation[] | null {
    return useSelector<State, Organisation[] | null>(s => s.organisations)
}
export function usePersons(): Person[] | null {
    return useSelector<State, Person[] | null>(s => s.persons)
}
export function useRegistrations(): Registration[] | null {
    return useSelector<State, Registration[] | null>(s => s.registrations)
}
export function useRoles(): Role[] {
    const user = useUser();
    const userOrg = useUserOrganisation();
    return [...(userOrg?.Roles || []), ...(user?.Roles || [])];
}

export function useUserOrganisation(): Organisation | null {
    const orgs = useOrganisations()
    const user = useUser()

    if (!user || !orgs) return null
    if (!user.OrganisationID) return null
    return orgs.find(o => o.OrganisationID === user.OrganisationID) || null
}
export function useCurOrganisation(): Organisation | null {
    const orgs = useOrganisations();
    const curOrgId = useSelector<State, string | null>(s => s.curOrganisationId)
    if (!curOrgId || !orgs) return null;
    return orgs.find(o => o.OrganisationID === curOrgId) || null
}
export function useCurOrganisationEmployees(): Person[] {
    const curOrg = useCurOrganisation();
    const persons = usePersons();
    if (!curOrg || !persons) return [];
    return persons.filter(p => p.OrganisationID == curOrg.OrganisationID)
}

export function useCurPerson(): Person | null {
    const pers = usePersons();
    const curPersId = useSelector<State, string | null>(s => s.curPersonId)
    if (!curPersId || !pers) return null;
    return pers.find(p => p.PersonID === curPersId) || null
}
export function useCurPersonOrganisation(): Organisation | null {
    const orgs = useOrganisations();
    const pers = useCurPerson();
    if (!pers || !orgs) return null;
    if (!pers.OrganisationID) return null;
    return orgs.find(o => o.OrganisationID === pers.OrganisationID) || null
}
export function useCurPersonRegistrations(): Registration[] | null {
    const regs = useRegistrations();
    const curPerson = useCurPerson();
    if (!regs || !curPerson) return null
    return regs.filter(r => r.PersonID === curPerson.PersonID)
}
export function useCurMonth(): Month | null {
    return useSelector<State, Month | null>(s => s.curMonth)
}



export const actions = {
    user: {
        async initiateLogin(email: string) {
            return rpc("user.InitiateLogin", { Email: email })
        },
        async finalizeLogin(jwt: string) {
            localStorage.setItem("jwt", jwt)
            await Promise.all([
                actions.user.get(),
                actions.projects.get(),
                actions.organisations.get(),
                actions.persons.get(),
                actions.registrations.get()
            ])
        },
        async logout() {
            localStorage.removeItem("jwt");
            store.dispatch({ type: "SET_USER", value: null })
        },
        async get() {
            await rpc("user.Get")
                .then(r => store.dispatch({ type: "SET_USER", value: r }))
        },
        async updateDetails(values: UserUpdateDetailsParams) {
            await rpc("user.UpdateDetails", values)
            await actions.user.get()
            await actions.persons.get()
        },
    },

    projects: {
        async get() {
            await rpc("projects.Get")
                .then(r => store.dispatch({ type: "SET_PROJECTS", value: r }))
        },
        async create(values: Project) {
            await rpc("projects.Create", values)
            await actions.projects.get()
        },
        async update(values: Project) {
            await rpc("projects.Update", values)
            await actions.projects.get()
        },
        async createActivity(values: Activity) {
            await rpc("projects.CreateActivity", values)
            await actions.projects.get()
        },
        async updateActivity(values: Activity) {
            await rpc("projects.UpdateActivity", values)
            await actions.projects.get()
        }
    },

    organisations: {
        async get() {
            await rpc("organisations.Get")
                .then(r => store.dispatch({ type: "SET_ORGANISATIONS", value: r }))
        },
        async updateDetails(values: OrganisationUpdateDetailsParams) {
            await rpc("organisations.UpdateDetails", values)
            await actions.organisations.get()
        },
        async sendAftale(orgId: string) {
            await rpc("organisations.SendAftale", { OrganisationID: orgId });
        },
        async refreshAftale(orgId: string) {
            await rpc("organisations.RefreshAftale", { OrganisationID: orgId });
            await actions.organisations.get()
        },
        async sendDeminimis(orgId: string) {
            await rpc("organisations.SendDeminimis", { OrganisationID: orgId });
        },
        async refreshDeminimis(orgId: string) {
            await rpc("organisations.RefreshDeminimis", { OrganisationID: orgId });
            await actions.organisations.get()
        },
        async inviteEmployee(values: OrganisationInviteEmployeeParams) {
            await rpc("organisations.InviteEmployee", values);
            await actions.persons.get()
        },
        async uninviteEmployee(personId: string) {
            await rpc("organisations.UninviteEmployee", { PersonID: personId })
            await actions.persons.get()
        },
        async updateEmployeePeriod(values: OrganisationUpdateEmployeePeriodParams) {
            await rpc("organisations.UpdateEmployeePeriod", values)
            await actions.persons.get()
        },
        async searchCVR(query: string) {
            return rpc("organisations.SearchCVR", { Query: query })
        },
        async listPUnits(cvr: string) {
            return rpc("organisations.ListPUnits", { CVR: cvr })
        },
        async invite(values: OrganisationInviteParams) {
            await rpc("organisations.Invite", values);
            await actions.organisations.get()
            await actions.persons.get()
        }
    },

    persons: {
        async get() {
            await rpc("persons.Get")
                .then(r => store.dispatch({ type: "SET_PERSONS", value: r }))
        },
        async createAllocation(values: PersonCreateAllocationParams) {
            await rpc("persons.CreateAllocation", values)
            await actions.persons.get()
        },
        async refreshAllocation(personID: string, caseFile: number) {
            await rpc("persons.RefreshAllocation", {PersonID: personID, CaseFile: caseFile})
            await actions.persons.get()
        }
    },

    registrations: {
        async get() {
            await rpc("registrations.Get")
                .then(r => store.dispatch({ type: "SET_REGISTRATIONS", value: r }))
        },
        async createTicket(values: RegistrationCeateTicketParams) {
            await rpc("registrations.CreateTicket", values)
        },
        async updateLines(values: RegistrationsUpdateLinesParams) {
            await rpc("registrations.UpdateLines", values)
        },
        async submit(values: RegistrationSubmitParams) {
            await rpc("registrations.Submit", values)
            await actions.registrations.get()
        },
        async reject(values: RegistrationRejectParams) {
            await rpc("registrations.Reject", values)
            await actions.registrations.get()
        },
        async accept(values: RegistrationAcceptParams) {
            await rpc("registrations.Accept", values)
            await actions.registrations.get()
        },
        async getLonseddelUploadLink(personId: string, month: Month): Promise<string> {
            return rpc("registrations.GetLonseddelUploadLink", { PersonID: personId, Month: month })
        },
        async getLonseddelDownloadLink(personId: string, month: Month): Promise<string> {
            return rpc("registrations.GetLonseddelDownloadLink", { PersonID: personId, Month: month })
        },
        async getTimeseddelDownloadLink(personId: string, month: Month, actID: string): Promise<string> {
            return rpc("registrations.GetTimeseddelDownloadLink", { PersonID: personId, Month: month, ActivityID: actID })
        },
    },

    test: {

    },


    selectOrganisation(orgId: string | null) {
        store.dispatch({ type: "SET_CUR_ORGANISATION_ID", value: orgId })
    },
    selectPerson(persId: string | null) {
        store.dispatch({ type: "SET_CUR_PERSON_ID", value: persId })
    },
    selectMonth(month: Month | null) {
        store.dispatch({ type: "SET_CUR_MONTH", value: month })
    }
}


type UserUpdateDetailsParams = {
    FirstName: string
    LastName: string
    CPR: string
    Mobile: string
    Ugenorm?: number
}
type OrganisationUpdateDetailsParams = {
    OrganisationID: string
    Tegningsberettiget: Signer
    Godkender: Signer
    SMV: boolean
    Deminimis: DeminimisLine[]
}
type OrganisationInviteEmployeeParams = {
    OrganisationID: string
    Email: string
    Start: Month | null
    End: Month | null
}
type OrganisationUpdateEmployeePeriodParams = {
    PersonID: string
    Start: Month | null
    End: Month | null
}
type OrganisationInviteParams = {
    Pno: string
    Email: string
    Afregning: Afregning
    Activities: string[]
}
type PersonCreateAllocationParams = {
    PersonID: string
    Lon: number
    Start: string
    Stilling: string
    Activities: {
        ActivityID: string
        Alloc: Alloc
        Lines: string[]
    }[]
    Other: {
        JournalNr: string
        Project: string
        Alloc: Alloc
    }[]
}
type RegistrationCeateTicketParams = {
    Subject: string
    Message: string
    PersonID: string
    Month: Month
}
type RegistrationsUpdateLinesParams = {
    PersonID: string
    Month: Month
    Activities: {
        ActivityID: string
        Lines: RegistrationActivityLine[]
    }[]
}
type RegistrationSubmitParams = {
    PersonID: string
    Month: Month
    Activities: {
        ActivityID: string
        Lines: RegistrationActivityLine[]
    }[]
}
type RegistrationRejectParams = {
    PersonID: string
    Month: Month
    Reason: string
}
type RegistrationAcceptParams = {
    PersonID: string
    Month: Month,
    AM?: DKK,
    ATP?: DKK,
    Pension?: DKK,
    Ferie?: DKK
}

export const store = createStore(
    reducer,
    /** @ts-ignore */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// @ts-ignore
window.actions = actions;