import { Button, Col, Input, Modal, Row, Table, TableColumnsType, Tabs } from "antd";
import { useRef } from "react";
import { useProjects } from "../../../utils/store";
import { rpc } from "../../../utils/utils";
import Projects from "./components/Projects";

export default function Admin() {
  const signInEmail = useRef<any>(null);
  const projects = useProjects();

  async function getSignInLink() {
    const email = signInEmail.current.input.value;
    const link = await rpc("admin.SignInAs", { Email: email })
    Modal.info({
      title: "Sign in link",
      content: <a href={link}>{link}</a>
    })

  }

  return (
    <Col flex="0 1 1200px">

      <Tabs>
        <Tabs.TabPane tab="Projekter" key="projekter">
          <Projects />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Auth" key="auth">

          <Row align="middle">
            <Col flex="0 0 100px">JWT:</Col>
            <Col flex={1}><Input readOnly value={localStorage.getItem("jwt") || ""} /></Col>
          </Row >

          <Row align="middle">
            <Col flex="0 0 100px">Sign in as:</Col>
            <Col flex={1}><Input ref={signInEmail} type="email" /></Col>
            <Col><Button onClick={getSignInLink}>Get sign in link</Button></Col>
          </Row>

        </Tabs.TabPane>

      </Tabs>
    </Col >
  )
}