import { Alert, Button, Col, Descriptions, Divider, Form, Input, InputNumber, message, Modal, Row, Select, Table, TableColumnsType } from "antd";
import { useState } from "react";
import { actions, useProjects } from "../../../../utils/store";
import { formatMonth, projectEnd, projectStart, rpc } from "../../../../utils/utils";

export default function Projects() {
  const projs = useProjects();
  const [curProjId, setCurProjId] = useState<string | null>(null)
  const [curActId, setCurActId] = useState<string | null>(null)

  const [showProjModal, setShowProjModal] = useState(false);
  const [showActModal, setShowActModal] = useState(false);

  const curProj = projs?.find(p => p.ProjectID === curProjId)
  const curAct = curProj?.Activities?.find(p => p.ActivityID === curActId)

  function onProjectSelect(id: string) {
    setCurProjId(id)
    setCurActId(null)
  }
  function onActivitySelect(id: string) {
    const projAlias = id.split('.')[0]

    const proj = projs?.find(p => p.Alias === projAlias) || null;
    setCurProjId(proj?.ProjectID || null)
    setCurActId(id)
  }

  function onAddProject() {
    setCurActId(null)
    setCurProjId(null)
    setShowProjModal(true)
  }
  function onEditProject() {
    setCurActId(null)
    setShowProjModal(true)
  }
  function onAddActivity() {
    setCurActId(null)
    setShowActModal(true)
  }
  function onEditActivity() {
    setShowActModal(true)
  }

  async function onProjectFinish(values: Project) {
    if (curProjId) await actions.projects.update(values);
    else await actions.projects.create(values);

    message.success("OK")
  }
  async function onActivityFinish(values: Activity) {
    console.log(values)

    if (curActId) await actions.projects.updateActivity(values);
    else await actions.projects.createActivity(values);

    message.success("OK")
  }

  function expandedRowRender(proj: Project) {
    const columns: TableColumnsType<Activity> = [
      { title: "Navn", dataIndex: "Name", render: (n, o) => <Button type="link" onClick={() => onActivitySelect(o.ActivityID)}>{n}</Button> },
      { title: "Start", dataIndex: "Start", render: (s) => formatMonth(s || projectStart) },
      { title: "Slut", dataIndex: "End", render: (s) => formatMonth(s || projectEnd) },
      { title: "Deminimis", dataIndex: "DeminimisEst", render: (s) => `${s} DKK` },
      { title: "ID", dataIndex: "ActivityID" }
    ]

    return <Table columns={columns} dataSource={proj.Activities || []} pagination={false} rowKey="ActivityID" />
  }
  const columns: TableColumnsType<Project> = [
    { title: "Navn", dataIndex: "Name", render: (n, o) => <Button type="link" onClick={() => onProjectSelect(o.ProjectID)}>{n}</Button> },
    { title: "Alias", dataIndex: "Alias" },
    { title: "Projektleder", dataIndex: ["ProjectManager", "Name"] },
    { title: "Bevilling", dataIndex: "Bevilling" },
    { title: "ID", dataIndex: "ProjectID" },
  ]

  return (
    <Col flex={1}>
      <Row>
        <Col flex={1}>
          <Table size="small" columns={columns} dataSource={projs || []} expandable={{ expandedRowRender }} pagination={false} rowKey="ProjectID" />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        {curProj &&
          <Col flex={"0 0 50%"}>
            <Descriptions title={`Projekt "${curProj.Name}"`} bordered size="small">
              <Descriptions.Item label="Arbejdsbeskrivelser">{curProj.Workdescrs?.map((w, i) => <Row key={i}>{w}</Row>)}</Descriptions.Item>
            </Descriptions>
          </Col>
        }
        {curAct &&
          <Col flex={"0 0 50%"}>
            <Descriptions title={`Aktivitet "${curAct.Name}"`} bordered size="small">
              <Descriptions.Item label="Arbejdsbeskrivelser">{curAct.Workdescrs?.map((w, i) => <Row key={i}>{w}</Row>)}</Descriptions.Item>
            </Descriptions>
          </Col>
        }
      </Row>


      <Row gutter={10} style={{ marginTop: 20 }}>
        <Col><Button onClick={onAddProject}>Tilføj projekt</Button></Col>
        <Col><Button onClick={onEditProject} disabled={curProjId == null}>Ændr projekt</Button></Col>
        <Col><Button onClick={onAddActivity} disabled={curProjId == null}>Tilføj aktivitet</Button></Col>
        <Col><Button onClick={onEditActivity} disabled={curActId == null}>Ændr aktivitet</Button></Col>
      </Row>



      <Modal destroyOnClose visible={showProjModal} title={curProj ? `Projekt "${curProj.Name}"` : "Nyt projekt"} onCancel={() => setShowProjModal(false)} footer={null}>
        <Form labelCol={{ span: 7 }} initialValues={curProj || undefined} onFinish={onProjectFinish}>
          <Form.Item name="ProjectID" hidden><Input /></Form.Item>
          <Form.Item name="Name" label="Navn"><Input /></Form.Item>
          <Form.Item name="Alias" label="Alias"><Input disabled={curProjId != null} /></Form.Item>
          <Form.Item name="Bevilling" label="Bevilling">
            <Select>
              <Select.Option key="REACTRF-210029">REACTRF-210029</Select.Option>
              <Select.Option key="REACTSF-210031">REACTSF-210031</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name={["ProjectManager", "Name"]} label="Projektleders navn"><Input /></Form.Item>
          <Form.Item name={["ProjectManager", "Email"]} label="Projektleders email"><Input /></Form.Item>
          <Divider>Arbejdsbeskrivelser</Divider>
          <Form.List name="Workdescrs">
            {
              (fields, { add, remove }) =>
                <>
                  {
                    fields.map((f, i) => (

                      <Row key={i}>
                        <Col flex={1}>
                          <Form.Item {...f}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button onClick={() => remove(i)} >Fjern</Button>
                        </Col>
                      </Row>
                    ))
                  }
                  <Row justify="center"><Button onClick={() => add()}>Tilføj</Button></Row>
                </>

            }
          </Form.List>
          <Button type="primary" htmlType="submit">OK</Button>
        </Form>
      </Modal>


      <Modal destroyOnClose visible={showActModal} title={curAct ? `Aktivitet "${curAct.Name}"` : "Ny aktivitet"} onCancel={() => setShowActModal(false)} footer={null}>
        <Alert style={{ marginBottom: 20 }} message={`Projekt: ${curProj?.Alias}`} />
        <Form labelCol={{ span: 7 }} initialValues={curAct || undefined} onFinish={onActivityFinish} >
          <Form.Item name="ActivityID" label="ActivityID"><Input disabled={curActId != null} /></Form.Item>
          <Form.Item name="Name" label="Navn"><Input /></Form.Item>
          <Form.Item name="DeminimisEst" label="DeminimisEst"><InputNumber /></Form.Item>


          <Divider>Arbejdsbeskrivelser</Divider>
          <Form.List name="Workdescrs">
            {
              (fields, { add, remove }) =>
                <>
                  {
                    fields.map((f, i) => (
                      <Row key={i}>
                        <Col flex={1}>
                          <Form.Item {...f}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Button onClick={() => remove(i)} >Fjern</Button>
                        </Col>
                      </Row>
                    ))
                  }
                  <Row justify="center"><Button onClick={() => add()}>Tilføj</Button></Row>
                </>

            }
          </Form.List>
          <Button type="primary" htmlType="submit">OK</Button>
        </Form>
      </Modal>
    </Col>
  )
}