import { Alert, Button, Col, message, Modal, Row } from "antd";
import { useState } from "react";
import { actions, useRoles, useUser, useUserOrganisation } from "../../../../utils/store";
import SplashLayout from "../../../layouts/SplashLayout";
import UserLayout from "../../../layouts/UserLayout";
import Error from "../../../shared/Error";
import Logo from "../../../shared/Logo";

export default function OrgSignAftaleModal() {
  const user = useUser();
  const userOrg = useUserOrganisation();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  if (!user || !userOrg) return null;

  if (!userOrg.Aftale?.SignLink) {
    return <Error title="Der er gået noget galt med signering af deltageraftale" />
  }

  async function onSend() {
    if (!userOrg) return null

    setLoading(true)
    await actions.organisations.sendAftale(userOrg.OrganisationID)
      .finally(() => { setLoading(false) })
    message.success(`Dokumentet er blevet sendt til underskrift til ${userOrg.Tegningsberettiget.Email}`)
    setSent(true)
  }

  async function onRefresh() {
    if (!userOrg) return null

    setLoading(true)
    await actions.organisations.refreshAftale(userOrg.OrganisationID)
      .finally(() => { setLoading(false) })
  }

  return (
    <UserLayout>
      <SplashLayout>

        <Modal visible closable={false} footer={null}>
          <Row justify="center"><Logo /></Row>
          <Row justify="center">

            <Alert type="info" description={
              <>
                <Row>Deltageraftalen skal underskrives digitalt af den tegningsberettiget:</Row>
                <Row style={{ marginBottom: 20 }} justify="center"><strong>{`${userOrg.Tegningsberettiget.Name} <${userOrg.Tegningsberettiget.Email}>`}</strong></Row>
                <Row justify="center">Når aftalen er underskrevet skal du trykke "Opdater status"</Row>
                <Row justify="center" style={{ marginTop: 20 }} gutter={10}>
                {
                  userOrg.Tegningsberettiget.Email === user.Email
                    ? 
                      <Col>
                        <Button type="primary" target="_blank" href={userOrg.Aftale.SignLink}>Underskriv nu</Button>
                      </Col>
                    :
                      <Col>
                        <Button disabled={sent} loading={loading} onClick={onSend} type="primary">Udsend e-mail</Button>
                      </Col>
                }
                  <Col>
                    <Button onClick={onRefresh} loading={loading}>Opdater status</Button>
                  </Col>
                </Row>
              </>
            } />

          </Row>
        </Modal >
      </SplashLayout>
    </UserLayout>
  )
}