import { Alert, Button, Col, Divider, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import { BiCheck, BiCommentDetail, BiErrorCircle, BiLock, BiPen, BiRefresh } from "react-icons/bi";
import { actions, useProjects } from "../../utils/store";
import { getLatestRegistrationLog } from "../../utils/utils";

type Props = {
    reg: Registration | null
}

export default function StatusAlert(props: Props) {
    const projs = useProjects();
    const [loading, setLoading] = useState(false);

    if (props.reg == null) return null;
    const regLog = getLatestRegistrationLog(props.reg);

    async function onUpdate() {
        setLoading(true);
        try {
            await actions.registrations.get()
        }
        finally {
            setLoading(false);
        }
    }

    let text: string;
    let type: string;
    let icon: JSX.Element;
    let extra: JSX.Element | null = null;

    switch (props.reg.Status) {
        case "rejected":
            text = "Registreringen er blevet afvist";
            type = "error"
            icon = <BiErrorCircle />;
            break;
        case "accepted":
            text = "Registreringen er godkendt"
            type = "success"
            icon = <BiCheck />;
            break;
        case "submitted":
            icon = <BiLock />
            text = "Registreringen er indsendt"
            type = "success"
            break;
        case "needs-signing":
            icon = <BiPen />
            type = "info"
            text = "Timeregistreringen er godkendt og skal underskrives"
            extra = (
                <>
                    <Divider style={{ margin: "10px 0" }} />
                    {
                        props.reg.Activities?.map(ra => {
                            const projAlias = ra.ActivityID.split(".")[0]
                            const p = projs?.find(p => p.Alias = projAlias)
                            if (!p) return null;
                            const a = p.Activities?.find(a => a.ActivityID == ra.ActivityID)
                            if (!a) return null;

                            return (
                                <Row gutter={10} key={a.ActivityID} justify="space-between" align="middle">
                                    <Col>
                                        <Row align="middle" style={{ fontSize: 16, fontWeight: 600, lineHeight: 1 }}> {p.Name}</Row>
                                        <Row align="middle" style={{ fontSize: 12, lineHeight: 1 }}> {a.Name}</Row>
                                    </Col>
                                    <Col>
                                        {ra.Timeseddel?.Signed
                                            ? <Tag color="green">Underskrevet</Tag>
                                            : <Button size="small" type="link" href={ra.Timeseddel?.SignLink} target="_blank">
                                                <Tag color="orange">
                                                    <Row align="middle">
                                                        <BiPen style={{ marginRight: 5 }} /> Underskriv nu
                                                    </Row>
                                                </Tag>
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Divider style={{ margin: "10px 0" }} />
                    <Row justify="center">
                        <Button size="small" type="primary" loading={loading} onClick={onUpdate}>
                            <Row align="middle">
                                <BiRefresh style={{ marginRight: 5 }} /> Opdater status
                            </Row>
                        </Button>
                    </Row>
                </>
            )

            break;
        default: return null
    }

    return (
        <Alert
            style={{ marginBottom: 20 }}
            icon={icon} showIcon
            message={<Row style={{ fontWeight: 600 }}>{text}</Row>}
            description={
                regLog?.Note
                    ?
                    <>
                        <Row align="top" style={{ flexWrap: "nowrap" }} gutter={5}>
                            <Col><BiCommentDetail style={{ marginTop: 5 }} /> </Col>
                            <Col>{regLog.Note}</Col>
                        </Row>
                        {extra}
                    </>
                    : extra
            }
            type={type as any}
        />
    )
}